<template>
  <div class="home">
    <div class="filter-wrapper">
      <div class="link-wrapper">
        <router-link
          v-if="computedIsAdmin"
          :to="{ name: 'SmsTemplate'}">
          Templates
        </router-link>
        <router-link :to="{ name: 'SmsLogs'}">
          Logs
        </router-link>
        <v-select
          v-if="!computedIsOperator"
          v-model="dataSelectLastDayValue"
          :options="dataSelectOptionsLastDay"
          :filterable="false"
          :searchable="false"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="Last Days"
          :class="['call-select', dataSelectLastDayValue ? 'call-select--value' : null]"
          @input="onSelectRefresh">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
        </v-select>
        <v-select
          v-model="dataSelectTeamsValue"
          :options="dataSelectOptionsTeam"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="All Teams"
          :class="['call-select', dataSelectTeamsValue ? 'call-select--value' : null]"
          @input="onSelectTeams">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template slot="no-options">
            Введите имя Team
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <v-select
          v-if="!computedIsOperator"
          v-model="dataSelectOperatorValue"
          :options="dataSelectOptionsOperator"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="All Operators"
          :class="['call-select', dataSelectOperatorValue ? 'call-select--value' : null]"
          @input="onSelectRefresh">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template slot="no-options">
            Введите имя Operator
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
      </div>
      <div>
        <call-pagination
          :page-value="50"
          :obj-pagination="dataObjPagination"
          @on-pagination="$_ajax" />
      </div>
    </div>
    <div class="table-wrapper">
      <b-table
        striped
        hover
        no-local-sorting
        :fields="dataMixinSmsLogFields"
        :items="dataSmsLogList"
        @sort-changed="onSortTabel"
        @row-hovered="onHover"
        @row-clicked="(value) => onActions(value, 'view')"
        @row-unhovered="onUnHover">
        <template #cell(created_at)="data">
          {{ data.item.created_at | dateFilter }}
        </template>
        <template #cell(player_id)="data">
          ID: {{ data.item.player_id }}
        </template>
        <template #cell(status)="data">
          <span
            v-if="data.item.status === 'success'"
            class="success"> <font-awesome-icon :icon="dataIconCheck" /> </span>
          <span
            v-else-if="data.item.status === 'error'"
            class="danger"> <font-awesome-icon :icon="dataIconError" /> </span>
          <span
            v-else-if="data.item.status === 'new'"
            class="warning"> <font-awesome-icon :icon="dataIconClock" /> </span>
        </template>
        <template #cell(actions)="data">
          <call-table-sms-logs-actions
            v-show="data.item.resend_available"
            @on-action="(value) => onActions(data.item, value)" />
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';
import { SMS_LOGS, SMS_RESEND, TEAMS_GET_ALL, USERS_GET_ALL } from '../../../store/types/actions.types';
import helper from '../../../service/helper';
import CallPagination from '@/components/desktop/CallPagination';
import smsLogTable from '@/mixins/table/smsLogTable';
import { faCheck, faClock, faTimes } from '@fortawesome/free-solid-svg-icons';
import CallTableSmsLogsActions from '@/components/desktop/CallTableSmsLogsActions';
import selectAllTeams from '../../../mixins/select/selectAllTeams';
import { mapGetters } from 'vuex';
import selectOperators from '../../../mixins/select/selectOperators';
import { ID_ROLE_OPERATOR } from '../../../service/consts';
import cloneDeep from 'lodash/cloneDeep';
import selectReportPeriod from '../../../mixins/select/selectReportPeriod';

export default {
  name: 'PageSms',
  components: {
    CallTableSmsLogsActions,
    CallPagination,
    BTable
  },
  mixins: [
    smsLogTable,
    selectAllTeams,
    selectOperators,
    selectReportPeriod
  ],
  data () {
    return {
      dataIconCheck: faCheck,
      dataIconError: faTimes,
      dataIconClock: faClock,
      dataObjPagination: {},
      dataSmsLogList: [],
      dataDesc: null,
      dataOrderBy: null
    };
  },
  computed: {
    ...mapGetters({
      computedIsOperator: 'auth/isOperator',
      computedIsModerator: 'auth/isModerator',
      computedCurrentUser: 'auth/currentUser',
      computedIsAdmin: 'auth/isAdmin'
    })
  },
  async created () {
    this.$_ajax = ((options = {}) => {
      let _page = 0;
      let _limit = 50;

      const { currentRoute: { name: pageName } = {} } = this.$router;

      const _filterKey = `_filter${pageName}`;

      if (!helper.isEmpty(this.$route.query, _filterKey)) {
        try {
          options = helper.clearObject(JSON.parse(this.$route.query[_filterKey]));

          const _arrFilter = [
            'desc',
            'orderBy',
            'limit',
            'page',
            'teamId',
            'operator_id',
            'period'
          ];

          for (const _key of _arrFilter) {
            if (!helper.isEmpty(options, _key)) {
              switch (_key) {
                case 'period':
                  this.dataSelectLastDayValue = options[_key];
                  break;
                case 'desc':
                  this.dataDesc = options[_key];
                  break;
                case 'orderBy':
                  this.dataOrderBy = options[_key];
                  break;
                case 'teamId':
                  this.dataSelectTeamsValue = options[_key];
                  break;
                case 'operator_id':
                  this.dataSelectOperatorValue = options[_key];
                  break;
                case 'limit':
                  _limit = options[_key];
                  break;
                case 'page':
                  _page = options[_key];
                  break;
              }
            }
          }
        } catch (e) {
          console.log('Error query params');
        }
      }

      return async ({ desc = this.dataDesc, orderBy = this.dataOrderBy, teamId = this.dataSelectTeamsValue, limit = null, page = null, reset = false } = options) => {
        if (!this.dataSelectLastDayValue) {
          this.dataSelectLastDayValue = { ...this.dataSelectLastDayValueDefault };
        }

        if (!helper.isEmpty(page) && page !== _page) {
          _page = page;
        }

        if (!helper.isEmpty(limit) && limit !== _limit) {
          _limit = limit;
        }

        if (reset) {
          _page = 0;
        }

        const [{ payload: { list = [] }, misc: { paging = {} } = {} }] = await Promise.all([
          this.$store.dispatch(`sms/${SMS_LOGS}`, {
            params: {
              desc,
              period: this.dataSelectLastDayValue?.key,
              order_by: orderBy,
              limit: _limit,
              page: _page,
              team_id: teamId?.id,
              operator_id: this.computedIsOperator ? this.computedCurrentUser?.id : this.dataSelectOperatorValue?.id
            }
          }),
          this.$_ajaxUserAll(teamId?.id)
        ]);

        this.dataObjPagination = paging;
        this.dataSmsLogList = list.map((item) => {
          item.actions = false;
          return item;
        });

        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            [_filterKey]: JSON.stringify(helper.clearObject({
              desc,
              period: this.dataSelectLastDayValue,
              orderBy,
              teamId,
              operator_id: this.computedIsOperator ? this.computedCurrentUser : this.dataSelectOperatorValue,
              limit: _limit,
              page: _page
            }))
          }
        }).catch(() => {});

        options = {};
      };
    })();

    this.$_ajaxUserAll = (() => {
      let _cacheAllUser = [];

      return async (idTeam = null) => {
        if (_cacheAllUser.length > 0 && !idTeam) {
          this.dataSelectOptionsOperator = _cacheAllUser;
          return;
        }

        try {
          const { payload: { list: listUser = [] } } = await this.$store.dispatch(`user/${USERS_GET_ALL}`, {
            params: {
              team_ids: idTeam ? [idTeam] : null,
              role: ID_ROLE_OPERATOR,
              short: true
            }
          });
          if (idTeam) {
            this.dataSelectOptionsOperator = listUser;
          } else {
            _cacheAllUser = cloneDeep(listUser);
            this.dataSelectOptionsOperator = listUser;
          }
        } catch (e) {
          console.log(e);
        }
      };
    })();

    const [{ payload: { list: listTeam = [] } }] = await Promise.all([
      this.$store.dispatch(`team/${TEAMS_GET_ALL}`, {
        params: {
          short: true
        }
      }),
      this.$_ajaxUserAll(),
      this.$_ajax()
    ]);

    this.dataSelectOptionsTeam = listTeam;
  },
  methods: {
    onSelectRefresh () {
      this.$_ajax({ reset: true });
    },
    onSelectTeams () {
      this.dataSelectOperatorValue = null;
      this.$_ajax({ reset: true });
    },
    onSortTabel ({ sortBy, sortDesc }) {
      this.dataDesc = sortDesc;
      this.dataOrderBy = sortBy;
      this.$_ajax();
    },
    // projects/60/tasks/1325
    async  onActions ({ id: smsId, task_id: taskId, project_id: projectId, player_id }, action) {
      switch (action) {
        case 'resend':
          await this.$store.dispatch(`sms/${SMS_RESEND}`, {
            smsId
          });
          await this.$_ajax();
          this.$Notify({
            title: 'Sms Resend',
            message: 'SMS sent again',
            type: 'success'
          });
          break;
        case 'view':
          this.$router.push({
            name: 'ProjectTasksDetail',
            params: {
              taskId,
              projectId
            },
            query: {
              taskName: `ID: ${player_id}`
            }
          });
          break;
      }
    }
  }
};
</script>
