export default {
  data () {
    return {
      dataMixinSmsLogFields: [
        {
          label: 'ID',
          key: 'id',
          sortable: false,
          tdClass: 'call-td call-td--grey',
          thClass: 'call-th'
        },
        {
          label: 'Sent',
          key: 'created_at',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Operator',
          key: 'operator_name',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Task',
          key: 'player_id',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Templatе',
          key: 'template_name',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Delivered',
          key: 'status',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center'
        },
        {
          label: 'Response',
          key: 'description',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'call-tabel-action',
          thClass: 'call-tabel-action'
        }
      ]
    };
  },
  methods: {
    onHover (item) {
      item.actions = true;
    },
    onUnHover (item) {
      item.actions = false;
    }
  }
};
